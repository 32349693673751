<template src="./CreateMasterManagementPage.html"></template>
<style scoped lang="scss" src="./CreateMasterManagementPage.scss"></style>

<script>
import {ServiceFactory} from "@/services";

const MasterDataForm = () => import('@/components/form/master-data/MasterDataForm');
const MasterDataService = ServiceFactory.get('masterData')

export default {
  name: 'CreateMasterData',
  components: {MasterDataForm},
  data: function () {
    return {
      isLoading: false
    }
  },
  methods: {
    async handleSubmit(formData) {
      this.isLoading = true
      const newMaster = await MasterDataService.create(formData).catch(() => {});
      this.isLoading = false

      if (!newMaster || !newMaster.id) return

      this.$notify.success({
        message: '新規登録は完了しました。',
        showClose: false
      });
      return this.$router.push({name: 'routes.masterManagement'})
    }
  }
}
</script>
